<template >
    <div>

            <img src="../assets/fb-foo.png" alt="">
            <img src="../assets/tt-foo.png" alt="">
            <img src="../assets/tw-foo.png" alt="">
            <img src="../assets/yt-foo.png" alt="">
            <img src="../assets/lin-foo.png" alt="">
            <img src="../assets/ins-foo.png" alt="">


         {{numUltimaJornada}}
         {{selected}}
         {{updateSelected}}

      {{ultimaJornada}}
      
     

    </div>
</template>
<script>

import { mapActions } from "vuex";
export default {
    name:'Test_Api_Externa',
    data(){
        return{
            status:'',
            msg:'',
            selected:0,
            updateSelected:0
        }
    },
    created(){
        this.getTablaGeneral()  //Julio - Diciembre
        this.getTablaGeneralClausura() // Enero - Mayo

        //------------------------------------------------
        /** PARAMETROS.
         *  idTemporada  -> 72   :La temporada 72 es la actual 2021-2022
         *  idTorneo     -> 1    :Torneo apertura Julio - Diciembre
         *  idDivision   -> 2    :Liga Expansión
         *  top         -> 10    : indica el top, modificar segun convenga.
         */
        let payload1 ={
            "idTemporada":72,
            "idTorneo":1,
            "idDivision":2,
            "top":10
        }
        this.getTablaGoleo(payload1);

        //------------------------------------------------
    let payload2 ={
         "idTorneo":1,
         "idDivision":2,
    }

    this.getJornadas(payload2)
 //------------------------------------------------
   
    let payload3 ={
         "idTorneo":1,
         "idDivision":2,
         "idJornada": 16,
    }
    this.getPartidos(payload3)
   let payload4 ={
         "idTorneo":1,
         "idDivision":2,
         "idTemporada": 72,
         "idClub": 10721
    }

    this.getPartidosClub(payload4)

    //------------------------------------------------
    let id = this.$route.params.id;
   
    let payload5 ={
        "idPartido": id
    }

   this.getAlineaciones(payload5)


let payload7 ={
         "idTorneo":1,
         "idDivision":2,
    }
this.getUltimaJornada(payload7)



 
    },
    computed:{
             tablaApertura(){
                     return this.$store.getters["tablaGeneral/data"];
            },
             tablaClausura(){
                     return this.$store.getters["tablaGeneralClausura/data"];
            },
            tablaGoleo(){
                return this.$store.getters["tablaGoleo/data"];
            },
            jornadas(){
                 return this.$store.getters["jornadas/data"];
            },
            partidos(){
                 return this.$store.getters["partidos/data"];
            },
            partidosClub(){
                 return this.$store.getters["partidos/data2"];
            },
            alineaciones(){
                 return this.$store.getters["alineaciones/data"];
            },
            ultimaJornada(){
                 return this.$store.getters["ultimaJornada/data"];
            },
             numUltimaJornada(){
                 let ultima =this.$store.getters["ultimaJornada/ultimaJornada"];
                 this.selected = ultima
                 this.updateSelected = ultima
                 return ultima
            }
    },
    methods:{
            ...mapActions('tablaGeneral', ['getTablaGeneral']),
            ...mapActions('tablaGeneralClausura', ['getTablaGeneralClausura']),
            ...mapActions('alineaciones', ['getAlineaciones']),
            ...mapActions('tablaGoleo', ['getTablaGoleo']),
            ...mapActions('partidos', ['getPartidos']),
            ...mapActions('partidos', ['getPartidosClub']),
            ...mapActions('jornadas', ['getJornadas']),
            ...mapActions('ultimaJornada', ['getUltimaJornada']),
    }
}
</script>
<style scoped>
    
</style>